var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isFetching)?_c('v-card',{staticClass:"card card-custom card-stretch gutter-b",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"font-weight-bolder text-dark table__header"},[_c('div',[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"card-toolbar"},[_c('ul',{staticClass:"nav nav-pills nav-pills-sm nav-dark-75"},[_c('li',{staticClass:"nav-item",on:{"click":() => {
            _vm.applyFilter('delivery');
          }}},[_c('a',{staticClass:"nav-link py-1 px-4 table__buttons",class:{
            'color__background--primary': _vm.filter === 'delivery',
          }},[_vm._v("Reparto")])]),_c('li',{staticClass:"nav-item",on:{"click":() => {
            _vm.applyFilter('local');
          }}},[_c('a',{staticClass:"nav-link py-1 px-4 table__buttons",class:{
            'color__background--primary': _vm.filter === 'local',
          }},[_vm._v("Recoger")])]),_c('li',{staticClass:"nav-item",on:{"click":() => {
            _vm.applyFilter('');
          }}},[_c('a',{staticClass:"nav-link py-1 px-4 table__buttons",class:{ 'color__background--primary': _vm.filter === '' }},[_vm._v("Todos")])])])])]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.deals?.length)+" ")]),(_vm.deals?.length !== 0)?_c('div',{staticClass:"card-body pt-0 pb-3"},[_c('v-simple-table',{staticClass:"table table-head-custom table-vertical-center table-head-bg table-bdealless bdealless",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',{staticClass:"text-left"},_vm._l((_vm.headers),function(item){return _c('th',{key:item,staticClass:"p-0 bdealless color__error"},[_vm._v(" "+_vm._s(item)+" ")])}),0)]),_c('tbody',_vm._l((_vm.deals),function(item){return _c('tr',{key:item._id,class:{ 'back-grey': _vm.isToday(item.createdAt) === false },on:{"click":() => {
              _vm.editItem(item);
            }}},[(item[_vm.filter] || _vm.filter === '')?[_c('td',{staticClass:"pb-4"},[(item.local)?_c('v-icon',{staticClass:"order--pagado",attrs:{"large":""}},[_vm._v(" mdi-basket ")]):_vm._e(),(item.delivery)?_c('v-icon',{staticClass:"order--pagado",attrs:{"large":""}},[_vm._v(" mdi-moped ")]):_vm._e()],1),_c('td',{staticClass:"pl-0 py-4"},[_c('span',{staticClass:"color__regular mb-1 font-14"},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('td',{staticClass:"pl-0 py-4"},[_c('span',{staticClass:"font-weight-bolder d-block font-14"},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.price))+" ")])]),_c('td',{staticClass:"pl-0 py-4"},[_c('span',{staticClass:"color__regular mb-1 font-14"},[_vm._v(" "+_vm._s(item.items.length)+" ")])]),_c('td',{staticClass:"pl-0 py-4"},[_c('router-link',{attrs:{"to":`/dealPage/${item._id} `}},[_c('v-icon',{staticClass:"color__text--primary",attrs:{"large":""}},[_vm._v(" mdi-chevron-right ")])],1)],1)]:_vm._e()],2)}),0)]},proxy:true}],null,false,1977614595)})],1):_c('div',{staticClass:"card-body pt-0 pb-3"},[_c('v-img',{staticStyle:{"margin":"0 auto"},attrs:{"src":"/media/misc/ZeroData.png","width":"500px","height":"200px"}})],1)],1):_c('v-card',{staticClass:"text-center",attrs:{"flat":"","min-height":"400px"}},[_c('h1',{staticClass:"mt-4"},[_vm._v("Cargando pedidos ...")]),_c('v-img',{staticStyle:{"margin":"0 auto"},attrs:{"src":"/media/loader/loading.gif","width":"300px","height":"300px"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }