<template>
  <v-card v-if="!isFetching" flat class="card card-custom card-stretch gutter-b">
    <v-card-title class="font-weight-bolder text-dark table__header">
      <div>{{ title }}</div>
      <div class="card-toolbar">
        <ul class="nav nav-pills nav-pills-sm nav-dark-75">
          <li class="nav-item" @click="
            () => {
              applyFilter('delivery');
            }
          ">
            <a class="nav-link py-1 px-4 table__buttons" v-bind:class="{
              'color__background--primary': filter === 'delivery',
            }">Reparto</a>
          </li>
          <li class="nav-item" @click="
            () => {
              applyFilter('local');
            }
          ">
            <a class="nav-link py-1 px-4 table__buttons" v-bind:class="{
              'color__background--primary': filter === 'local',
            }">Recoger</a>
          </li>
          <li class="nav-item" @click="
            () => {
              applyFilter('');
            }
          ">
            <a class="nav-link py-1 px-4 table__buttons"
              v-bind:class="{ 'color__background--primary': filter === '' }">Todos</a>
          </li>
        </ul>
      </div>
    </v-card-title>
    <v-card-subtitle>{{ deals?.length }} </v-card-subtitle>
    <div class="card-body pt-0 pb-3" v-if="deals?.length !== 0">
      <v-simple-table class="table table-head-custom table-vertical-center table-head-bg table-bdealless bdealless">
        <template v-slot:default>
          <thead>
            <tr class="text-left">
              <th v-for="item in headers" :key="item" class="p-0 bdealless color__error">
                {{ item }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="item in deals" :key="item._id" @click="
              () => {
                editItem(item);
              }
            " v-bind:class="{ 'back-grey': isToday(item.createdAt) === false }">
              <template v-if="item[filter] || filter === ''">
                <td class="pb-4">
                  <v-icon v-if="item.local" large class="order--pagado">
                    mdi-basket
                  </v-icon>
                  <v-icon v-if="item.delivery" large class="order--pagado">
                    mdi-moped
                  </v-icon>
                </td>

                <!-- NOMBRE OFERTA -->
                <td class="pl-0 py-4">
                  <span class="color__regular mb-1 font-14">
                    {{ item.name }}
                  </span>
                </td>

                <!-- PRECIO OFERTA -->
                <td class="pl-0 py-4">
                  <span class="font-weight-bolder d-block font-14">
                    {{ item.price | formatPrice }}
                  </span>
                </td>

                <td class="pl-0 py-4">
                  <span class="color__regular mb-1 font-14">
                    {{ item.items.length }}
                  </span>
                </td>

                <td class="pl-0 py-4">
                  <router-link :to="`/dealPage/${item._id} `">
                    <v-icon large class="color__text--primary">
                      mdi-chevron-right
                    </v-icon>
                  </router-link>
                </td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <div class="card-body pt-0 pb-3" v-else>
      <v-img src="/media/misc/ZeroData.png" width="500px" height="200px" style="margin:0 auto;" />
    </div>
  </v-card>
  <v-card v-else flat min-height="400px" class="text-center">
    <h1 class="mt-4">Cargando pedidos ...</h1>
    <v-img src="/media/loader/loading.gif" width="300px" height="300px" style="margin:0 auto;" />
  </v-card>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  props: {
    deals: {
      type: Array,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    finalizados: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      editedIndex: -1,
      editedItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      headers: ["", "Nombre", "Precio", "N. bloques", " "],
      filter: "",
    };
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.deals.isFetching,
    }),
  },
  methods: {
    isToday(value) {
      let formatType = "LL";
      if (!value) return "";
      let dateFormated = moment(value);
      let today = moment();
      dateFormated.locale("es");
      today.locale("es");
      return dateFormated.format(formatType) === today.format(formatType);
    },
    showSnackbar(msg) {
      this.snackbar.text = msg;
      this.snackbar.show = true;
    },
    editItem(item) {
      // console.log(item)
      this.$emit("dealClicked", item);
    },
    applyFilter(value) {
      this.filter = value;
    },
    // isCloseTakeAway(str){
    //     let now=new Date()
    //     let recogida = new Date(str)
    //     if((((recogida.getTime()- now.getTime())/60000) < 20) && (((recogida.getTime()- now.getTime())/60000) > 0) ){
    //         return true
    //     }
    //     return false
    // }
  },
};
</script>

<style lang="scss">
.table__header {
  font-size: 16px;
  justify-content: space-between;
}

.table__buttons {
  font-size: 13px;
  font-weight: 400;
}

.bdealless {
  bdeal-bottom: none !important;
}

.h-50 {
  height: 50% !important;
}

.back-grey {
  background-color: beige;
}
</style>
